<template>
  <v-card>
    <v-card-title>
      Cancelar orden
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Número de orden"
              type="text"
              :value="orderNumber"
              disabled
            />
            <v-select
              label="Motivo de cancelación"
              v-model="cancellation.id_motivo_cancelacion"
              :items="cancellationReasons"
              item-text="dato"
              item-value="id"
              dense
              solo
            />
            <v-textarea
              label="Comentarios"
              v-model="cancellation.comentarios"
              rows="3"
              solo
            ></v-textarea>
            {{ orderId }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="emitCancellationData"
      >
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    orderId: {
      type: Number,
      default: null
    },
    orderNumber: {
      type: String,
      default: ''
    },
    cancellationReasons: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cancellation: {
        id_orden: null,
        id_usuario: null,
        id_motivo_cancelacion: null,
        id_usuario_aprobador: 0,
        comentarios: null
      }
    }
  },
  methods: {
    emitCancellationData() {
      this.cancellation.id_orden = this.orderId
      this.$emit('cancellationData', this.cancellation)      
    },
  }
}
</script>
